import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import clsx from 'clsx'
import Web3Status from 'components/Web3Status'
import { chainIdToBackendName } from 'graphql/data/util'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { useAtomValue } from 'jotai/utils'
import { Box } from 'nft/components/Box'
import { useProfilePageState } from 'nft/hooks'
import { ReactNode, useState } from 'react'
import { NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
import styled from 'styled-components/macro'

import { ChainSelector } from './ChainSelector'
import { MenuDropdown } from './MenuDropdown'
import * as styles from './style.css'

const Nav = styled.nav`
  padding: 20px 12px;
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 2;
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()

  const shouldDisableNFTRoutes = useAtomValue(shouldDisableNFTRoutesAtom)

  return (
    <>
      <MenuItem href="/swap" isActive={pathname.startsWith('/swap')}>
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem href={`/tokens/${chainName.toLowerCase()}`} isActive={pathname.startsWith('/tokens')}>
        <Trans>Tokens</Trans>
      </MenuItem>
      {!shouldDisableNFTRoutes && (
        <MenuItem dataTestId="nft-nav" href="/nfts" isActive={isNftPage}>
          <Trans>NFTs</Trans>
        </MenuItem>
      )}
      <Box display={{ sm: 'flex', lg: 'none', xxl: 'flex' }} width="full">
        <MenuItem href="/pools" dataTestId="pool-nav-link" isActive={isPoolActive}>
          <Trans>Pools</Trans>
        </MenuItem>
      </Box>
      <Box marginY={{ sm: '4', md: 'unset' }}>
        <MenuDropdown />
      </Box>
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [openMenu, setOpenMenu] = useState(false)
  const soon = () => {
    window.location.href = '/#/swap'
  }
  const location = useLocation()
  const isOnExplorer = location.pathname.endsWith('explorer')
  return (
    <>
      {pathname === '/' ? (
        <header className="top-[46px] max-sm:top-[30px] left-0 right-0 mx-auto w-[864px] max-w-[calc(100%-40px)] fixed rounded-[60px] bg-[rgba(255,255,255,0.04)] backdrop-blur-[10px] px-[24px] max-sm:px-[15px] flex justify-between items-center">
          <a href="/">
            <img src="/img/logo3.png" className="h-[68px]" alt="" />
          </a>
          <div
            onClick={() => setOpenMenu(!openMenu)}
            className="max-sm:flex items-center justify-center rounded-full size-[48px] bg-[rgba(255,255,255,0.08)] flex-shrink-0 hidden"
          >
            <img src="/img/more.svg" alt="" />
          </div>
          <ul
            className={`flex gap-8 items-center max-sm:absolute max-sm:top-[85px] max-sm:right-0 max-sm:rounded-[30px] max-sm:p-[10px] max-sm:bg-[#232323] max-sm:flex-col max-sm:gap-4 ${
              openMenu ? '' : 'max-sm:hidden'
            }`}
          >
            <li className="relative">
              <a href="https://docs.newera.finance" className="text-white opacity-50 hover:opacity-100">
                Docs
              </a>
            </li>
            <li className="relative max-sm:hidden">
              <a href="https://x.com/newera_finance">
                <img src="/img/twitter.svg" alt="" />
              </a>
              {/* <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-[35px] left-1/2 -translate-x-1/2 px-[10px] py-[5px]">
                Soon
              </span> */}
            </li>
            <li>
              <button
                onClick={soon}
                className="h-[48px] rounded-full bg-[rgba(255,255,255,0.08)] text-[16px] text-white px-[30px]"
              >
                Launch App
              </button>
            </li>
          </ul>
        </header>
      ) : pathname === '/soon' ? (
        <header className="top-[46px] max-sm:top-[30px] left-0 right-0 mx-auto w-[1024px] max-w-[calc(100%-40px)] fixed rounded-[60px] bg-[rgba(255,255,255,0.04)] backdrop-blur-[10px] px-[24px] flex justify-between items-center">
          <a href="/">
            <img src="/img/logo3.png" className="h-[68px]" alt="" />
          </a>
          <div
            onClick={() => setOpenMenu(!openMenu)}
            className="max-sm:flex items-center justify-center rounded-full size-[48px] bg-[rgba(255,255,255,0.08)] flex-shrink-0 hidden"
          >
            <img src="/img/more.svg" alt="" />
          </div>
          <ul
            className={`hidden gap-8 items-center max-sm:absolute max-sm:top-[85px] max-sm:right-0 max-sm:rounded-[30px] max-sm:p-[10px] max-sm:bg-[#232323] max-sm:flex-col max-sm:gap-4 ${
              openMenu ? 'max-sm:flex' : 'max-sm:hidden'
            }`}
          >
            <li className="relative">
              <a href="https://docs.newera.finance" className="text-white opacity-50 hover:opacity-100">
                Docs
              </a>
            </li>
            <li className="relative max-sm:hidden">
              <img src="/img/twitter.svg" alt="" />
              <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-[35px] left-1/2 -translate-x-1/2 px-[10px] py-[5px]">
                Soon
              </span>
            </li>
            <li>
              <button
                onClick={soon}
                className="h-[48px] rounded-full bg-[rgba(255,255,255,0.08)] text-[16px] text-white px-[30px]"
              >
                Launch App
              </button>
            </li>
          </ul>
          <div className="flex gap-[30px] max-sm:hidden">
            <ul className="flex gap-8 items-center">
              <li className="relative">
                <a className={clsx('text-white', { 'opacity-50': isOnExplorer })} href="/#/swap">
                  Swap
                </a>
              </li>
              <li className="relative">
                <a className={clsx('text-white', { 'opacity-50': !isOnExplorer })} href="/#/explorer">
                  Explorer
                </a>
              </li>
              <li className="relative">
                <a className="text-white opacity-50" href="#">
                  Pools
                </a>
                <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-[20px] left-1/2 -translate-x-1/2 px-[10px] py-[5px]">
                  Soon
                </span>
              </li>
            </ul>
            <div className="flex gap-[10px] opacity-50 pointer-events-none">
              <ChainSelector />
              <Web3Status />
            </div>
          </div>
        </header>
      ) : (
        <header className="top-[46px] left-0 right-0 mx-auto w-[1024px] absolute rounded-[60px] bg-[rgba(255,255,255,0.04)] backdrop-blur-[10px] px-[24px] flex justify-between items-center max-w-[calc(100%-40px)] max-sm:bg-[#201e26]">
          <a href="/" className="block h-[68px] flex justify-center items-center">
            <img src="/img/logo3.png" className="max-h-[68px] max-sm:hidden" alt="" />
            <img src="/img/gradient-logo.png" className="max-h-[30px] hidden max-sm:block" alt="" />
          </a>
          <div className="flex gap-[30px]">
            <ul className="flex gap-6 items-center max-sm:hidden">
              <li>
                <a className={clsx('text-white', { 'opacity-50': isOnExplorer })} href="/#/swap">
                  Swap
                </a>
              </li>
              <li className="relative">
                <a className="text-white" href="/#/explorer">
                  Explorer
                </a>
              </li>
              <li className="relative">
                <span className="text-white opacity-50" href="#">
                  Pools
                </span>
                <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-[35px] left-0 px-[10px] py-[5px]">
                  Soon
                </span>
              </li>
            </ul>
            <div className="flex gap-[10px]">
              <ChainSelector />
              <Web3Status />
            </div>
          </div>
        </header>
      )}
      {/* {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <UniIcon
                width="48"
                height="48"
                data-testid="uniswap-logo"
                className={styles.logo}
                onClick={() => {
                  navigate({
                    pathname: '/',
                    search: '?intro=true',
                  })
                }}
              />
            </Box>
            {!isNftPage && (
              <Box display={{ sm: 'flex', lg: 'none' }}>
                <ChainSelector leftAlign={true} />
              </Box>
            )}
            <Row display={{ sm: 'none', lg: 'flex' }}>
              <PageTabs />
            </Row>
          </Box>
          <Box className={styles.searchContainer}>
            <SearchBar />
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Box position="relative" display={{ sm: 'flex', navSearchInputVisible: 'none' }}>
                <SearchBar />
              </Box>
              {isNftPage && sellPageState !== ProfilePageStateType.LISTING && <Bag />}
              {!isNftPage && (
                <Box display={{ sm: 'none', lg: 'flex' }}>
                  <ChainSelector />
                </Box>
              )}

              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav> */}
    </>
  )
}

export default Navbar
