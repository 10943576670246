import decentExchangePng from 'assets/images/landing-coins.png'
import IntroductionSection from 'components/Landing/IntroductionSection'
import JoinUsSection from 'components/Landing/JoinUsSection'
import JustForYouSection from 'components/Landing/JustForYouSection'
import OnchainMarketplaceSection from 'components/Landing/OnchainMarketplaceSection'
import OptimizedTradesSection from 'components/Landing/OptimizedTradesSection'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react'

gsap.registerPlugin(ScrollTrigger)

export default function Landing() {
  const soon = () => {
    window.location.href = '/#/swap'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const coins = gsap.utils.toArray<HTMLDivElement>('#icons > div')
    const allHeight = window.innerHeight * 1.25
    const tl = gsap
      .timeline({
        // yes, we can add it to an entire timeline!
        paused: false,
        scrollTrigger: {
          scrub: true,
          start: 'top top',
          end: () => '+=' + (allHeight + 100),
        },
      })
      .to('#c1', { top: '50%', duration: 0.2 }, 0)
      .to('#c2', { top: '50%', duration: 0.2 }, 0)
      .to('#c3', { top: '50%', duration: 0.2 }, 0)
      .to('#c3', { duration: 0.3, opacity: 0 }, 0.15)
      .to('#c1', { duration: 0.5, opacity: 1 }, 0.15)
      .to('#c4', { duration: 0.2, scale: 1, opacity: 0.15 }, 0.05)
      .to('#c5', { duration: 0.2, scale: 1, opacity: 0.15 }, 0.1)
      .to('#c6', { duration: 0.2, scale: 1, opacity: 0.15 }, 0.15)
      .to('#scroll-down-svg', { opacity: 0, duration: 0.25 }, 0)
      .to('#scroll-down-svg', { top: 400, duration: 0.25 }, 0)

    coins.forEach((coin, index) => {
      tl.to(coin, { opacity: 1 }, 0.2 + +index * 0.02)
    })

    const deTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: '#decent-exchange-section',
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      })
      .to('#decent-exchange-els', { opacity: 1, duration: 0.15 }, 0.15)
      .to('#decent-exchange-els', { scale: 1, duration: 0.3 }, 0.1)
      .to('#decent-exchange-png', { opacity: 1, duration: 0.3 }, 0.15)
      .to('#decent-exchange-png', { scale: 2, duration: 1 }, 0.1)
      .to('#decent-exchange-png', { opacity: 0, duration: 0.3 }, 0.75)

    document.querySelectorAll<HTMLDivElement>('.usp-card-wrapper').forEach((card) => {
      const spotlight = card.querySelector<HTMLDivElement>('.spotlight')

      card.addEventListener('mousemove', (e) => {
        const rect = card.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        if (spotlight) {
          spotlight.style.top = `${y}px`
          spotlight.style.left = `${x}px`
        }
      })

      // card.addEventListener("mouseleave", () => {
      //   spotlight.style.transform = `translate(-50%, -50%)`;
      // });
    })

    return () => {
      tl.scrollTrigger?.kill()
      tl.kill()
      deTl.scrollTrigger?.kill()
      deTl.kill()
    }
  }, [])

  return (
    <div id="page-wrapper" className="w-full">
      <section className="min-h-[65vh] w-screen relative">
        <div className="h-52 w-10/12 max-w-[638px] rounded-full bg-white blur-[244px] absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full max-sm:opacity-50"></div>

        {/* <img src="/img/circle.png" className="size-[600px] absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2" alt="" />
      <img src="/img/arrow.svg" className="px-[12px] py-[18px] bg-black rounded-[60px] top-full translate-y-[50px] left-0 right-0 mx-auto absolute" alt="" />
      <div className="size-[500px] rounded-full opacity-35 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div>
      <div className="size-[600px] rounded-full opacity-25 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div>
      <div className="size-[700px] rounded-full opacity-10 absolute top-full left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"></div> */}
        <div className="w-full h-1/2 absolute left-0 top-0 bg-[url(./img/pattern.png)] bg-contain mix-blend-color-burn max-sm:hidden"></div>
        <div className="w-full h-1/2 absolute left-0 bottom-0 bg-[url(./img/pattern.png)] bg-contain mix-blend-overlay backdrop-blur-[32px] z-10 max-sm:hidden"></div>
        <div className="hero absolute w-full left-0 top-1/2 -translate-y-1/2 text-center z-20 max-sm:px-[20px]">
          <div className="flex justify-center gap-2 items-center">
            <span className="text-[56px] text-white font-extralight max-sm:text-[20px] italic">The</span>
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Secondary Market</span>
            <span className="text-[56px] text-white font-extralight max-sm:text-[20px] italic">For</span>
          </div>
          <div className="flex justify-center gap-2 items-center">
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Real World</span>
            <img src="/img/globe.png" className="h-[52px] max-sm:h-[30px]" alt="" />
            <span className="text-[56px] text-white font-medium max-sm:text-[20px]">Assets</span>
          </div>
          <img src="/img/border.png" className="w-[1121px] mx-auto -mt-8 max-sm:hidden" />
        </div>
        <div className="absolute left-0 right-0 mx-auto bottom-[60px] max-sm:bottom-[100px] flex flex-col gap-4 w-[280px] z-10">
          <input
            onClick={soon}
            type="submit"
            value="Launch App"
            className="bg-[#030303] shadow-[0px_34px_74px_0px_#000] rounded-[60px] h-[56px] text-center max-sm:shadow-[0px_4px_114px_0px_rgba(118,5,232,0.63)] max-sm:bg-[conic-gradient(from_-6deg_at_100%_0%,#FFF_15.605940967798233deg,#FFF_117.24214553833008deg,#FF2748_235.38886070251465deg,#6200FF_312.7477169036865deg,#FF1A41_357.0234990119934deg)]"
          />
        </div>
      </section>
      <section className="h-[1024px] max-sm:h-[150vw] w-full relative">
        <img
          src="/img/tokens.png"
          id="c1"
          className="size-[414px] max-sm:w-1/2 max-sm:h-auto max-sm:hidden blur-[100px] absolute left-0 right-0 mx-auto top-0 -translate-y-1/2 opacity-0"
          alt=""
        />
        <img
          src="/img/tokens.png"
          id="c2"
          className="size-[414px] max-sm:w-1/2 max-sm:h-auto max-sm:!top-1/2 absolute left-0 right-0 mx-auto top-0 -translate-y-1/2"
          alt=""
        />
        <img
          src="/img/circle.png"
          id="c3"
          className="size-[584px] max-sm:w-[70%] max-sm:h-auto max-sm:hidden absolute left-0 right-0 mx-auto top-0 -translate-y-1/2"
          alt=""
        />
        <img
          src="/img/scroll-down.svg"
          id="scroll-down-svg"
          className="max-sm:hidden w-8 h-16 absolute top-32 left-1/2 -translate-x-1/2"
          alt="Scroll Down"
        />
        <div
          id="c4"
          className="size-[554px] max-sm:w-[60%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div
          id="c5"
          className="size-[720px] max-sm:w-[70%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div
          id="c6"
          className="size-[870px] max-sm:w-[80%] max-sm:aspect-square max-sm:h-auto rounded-full opacity-0 scale-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-white"
        ></div>
        <div id="icons">
          <div className="size-[72px] max-sm:size-[30px] max-sm:top-[24%] absolute opacity-0 top-[117px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] left-1/2 -translate-x-1/2 backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDY
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[20%] max-sm:top-[32%] max-sm:transform-none absolute opacity-0 top-[225px] left-1/2 translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token9.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              OpenEden
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[20%] max-sm:top-[32%] max-sm:transform-none absolute opacity-0 top-[225px] right-1/2 -translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token11.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDC
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[11%] max-sm:top-[50%] max-sm:translate-x-0 absolute opacity-0 top-1/2 -translate-y-1/2 left-1/2 translate-x-[320px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/usdl.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDL
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[11%] max-sm:top-[50%] max-sm:translate-x-0 absolute opacity-0 top-1/2 -translate-y-1/2 right-1/2 -translate-x-[320px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token6.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              USDY
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:left-auto max-sm:right-[20%] max-sm:bottom-[32%] max-sm:transform-none absolute opacity-0 bottom-[225px] left-1/2 translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/token3.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              Backed Finance
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:right-auto max-sm:left-[20%] max-sm:bottom-[32%] max-sm:transform-none absolute opacity-0 bottom-[225px] right-1/2 -translate-x-[220px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/pax-gold.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              PAX Gold
            </span>
          </div>

          <div className="size-[72px] max-sm:size-[30px] max-sm:bottom-[24%] absolute opacity-0 bottom-[117px] rounded-full bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] left-1/2 -translate-x-1/2 backdrop-blur-[30px] group cursor-pointer">
            <img
              src="/img/buidl.png"
              className="size-[40px] max-sm:size-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
              alt=""
            />
            <span className="px-[20px] py-[12px] max-sm:hidden text-white absolute rounded-[60px] bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.30)] backdrop-blur-[60px] top-0 left-full -translate-y-[30px] translate-x-[5px] transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-[10px]">
              BUIDL
            </span>
          </div>
        </div>
      </section>
      <IntroductionSection />
      <section id="decent-exchange-section" className="relative py-[200px] w-full">
        <div id="decent-exchange-els" className="w-full flex flex-col justify-center items-center scale-0 opacity-0">
          <h3 className="text-[38px] font-semibold text-center px-6 max-sm:text-xl max-w-3xl">
            The Secondary Marketplace <span className="font-light">for the</span> New Era of Finance
          </h3>
          <p className="text-xl opacity-50 px-6 max-sm:text-base text-center mt-6 max-sm:mt-4">
            non-custodial, optimized trading, single interface
          </p>
        </div>
        <img
          id="decent-exchange-png"
          src={decentExchangePng}
          className="min-w-[760px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-0 opacity-0"
        />
        <a
          href="https://docs.newera.finance/"
          target="_blank"
          className="flex justify-center items-center mt-8 text-white bg-white/10 rounded-full font-semibold max-sm:text-[14px] max-sm:justify-center px-6 py-4 w-72 relative z-20 mx-auto"
          rel="noreferrer"
        >
          Learn More
        </a>
      </section>

      <section className="py-[200px] max-sm:py-[50px] w-full">
        <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto space-y-6 max-sm:space-y-2">
          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[500px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b1.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Supporting</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Top Coins</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b2.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">From</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">+8 Chains</p>
              </div>
            </div>
          </div>

          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[376px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b3.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">+130</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Aggregation Source</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b4.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">No</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Min Investment</p>
              </div>
            </div>
          </div>

          <div className="flex gap-6 max-sm:gap-2">
            <div className="usp-card-wrapper group flex-shrink-0 w-[500px] max-sm:w-1/2 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b5.png"
                className="max-sm:relative absolute right-0 max-h-full top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">Non-Custodial</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Your Keys. Your Coins.</p>
              </div>
            </div>

            <div className="usp-card-wrapper group flex-1 rounded-[28px] max-sm:rounded-[16px] h-[276px] max-sm:h-auto relative bg-[rgba(255,255,255,0.07)] border border-[rgba(255,255,255,0.1)] backdrop-blur-[30px] overflow-hidden">
              <div className="spotlight blur-[50px] absolute w-[200px] h-[200px] bg-white bg-opacity-30 rounded-full opacity-0 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-50"></div>
              <img
                src="/img/b6.png"
                className="max-sm:relative absolute right-0 max-h-full max-sm:translate-x-[5%] top-0 grayscale transition-all group-hover:grayscale-0"
                alt=""
              />
              <div className="absolute left-0 bottom-0 p-[32px] max-sm:p-[20px]">
                <span className="text-white opacity-50 max-sm:text-[14px]">RWA</span>
                <p className="text-white font-medium text-[20px] max-sm:text-[16px]">Market Insights</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <OptimizedTradesSection />

      <section className="pt-[100px] pb-[200px] w-full">
        <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto flex flex-col max-sm:items-center">
          <p className="opacity-50 text-xl max-sm:text-sm max-sm:text-center">NewEra Explorer</p>
          <p className="text-[38px] font-semibold max-sm:text-[18px] mt-5 max-sm:text-center">
            Make informed decisions with{' '}
            <span className="font-bold italic">real-time market data and third party ratings</span>
          </p>
          <div className="mt-5 max-sm:text-center">
            <a
              href="https://docs.newera.finance/"
              target="_blank"
              className="flex justify-center items-center mt-8 text-white bg-white/10 rounded-full font-semibold max-sm:text-[14px] max-sm:justify-center px-6 py-4 w-72"
              rel="noreferrer"
            >
              Learn More
            </a>
          </div>
          <div className="relative w-full flex items-center justify-center"></div>
          <div className="flex justify-center items-center mt-14 relative">
            <img
              src="/img/landing-explorer-gl.png"
              className="absolute -bottom-16 sm:-bottom-[94px] left-1/2 -translate-x-1/2 z-0 size-72 sm:size-[476px] opacity-60 blur-[144px]"
              alt=""
            />
            <img
              src="/img/landing-explorer-gl.png"
              className="absolute -bottom-16 sm:-bottom-[94px] left-1/2 -translate-x-1/2 z-0 size-48 sm:size-[348px]"
              alt=""
            />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0 scale-125 size-full rounded-[50%] bg-white/15 blur-[200px] max-sm:hidden"></div>
            <img
              src="/img/landing-explorer.png"
              className="absolute top-0 left-0bg-[#030109] backdrop-blur-[114px] rounded-[28px] z-0 blur-[54px]"
              alt=""
            />
            <img
              src="/img/landing-explorer.png"
              className="bg-[#030109]/[54%] backdrop-blur-[114px] rounded-[28px] relative z-10"
              alt=""
            />
            <img
              src="/img/landing-explorer-bg.png"
              className="absolute max-sm:hidden top-[10%] -left-16 grayscale opacity-50 rotate-[-5deg] w-10/12 h-10/12 z-0"
              alt=""
            />
            <img
              src="/img/landing-explorer-bg.png"
              className="absolute max-sm:hidden top-[10%] -right-16 grayscale opacity-50 rotate-[5deg] w-10/12 h-10/12 z-0"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="py-[100px] w-full relative">
        <div className="relative z-20 w-[900px] max-w-[calc(100%-20px)] mx-auto flex items-center max-sm:flex-col gap-10">
          <div className="w-full flex flex-col space-y-5 max-sm:items-center">
            <div className="size-24">
              <img src="/img/tag-user.png" alt="" />
            </div>
            <p className="opacity-50 text-xl max-sm:text-sm max-sm:text-center">NewEra KYC</p>
            <p className="text-[38px] font-semibold max-sm:text-[18px] mt-5 max-sm:text-center leading-none">
              <span className="font-bold italic">Reusable KYC</span> for Security Tokens
            </p>
            <p className="opacity-50 text-xl max-sm:text-sm mt-5 max-sm:text-center">
              Access all KYC-required RWA Tokens with a single verification. Start trading smarter.
            </p>
          </div>
          <div className="w-full h-full max-sm:max-w-72 mx-auto">
            <img src="/img/landing-kyc.png" alt="" />
          </div>
        </div>
        <div
          className="z-10 w-[235px] h-[1340px] absolute -top-52 left-1/2 -translate-x-1/2 rotate-[-53deg] blur-[154px] max-sm:hidden opacity-60"
          style={{
            background:
              'conic-gradient(from -87deg at 103.9% 5.68%, #FF1A41 -50.39deg, #FFFFFF 17.24deg, #FFFFFF 117.24deg, #FF2748 130.57deg, #6200FF 233.74deg, #FF1A41 309.61deg, #FFFFFF 377.24deg)',
          }}
        ></div>
      </section>

      <JustForYouSection />

      <JoinUsSection />

      <OnchainMarketplaceSection />

      <footer className="py-[100px] max-sm:py-[50px] relative w-full">
        <img src="/img/elipse3.png" className="absolute bottom-0 w-full pointer-events-none" alt="" />
        <div className="w-full h-full absolute left-0 top-0 bg-[url(./img/pattern.png)] mix-blend-color-burn opacity-40 max-sm:hidden"></div>
        {/* <img src="/img/elipse2.png" className="absolute top-0 left-[40%] -translate-x-1/2 h-[345px]" alt="" /> */}
        <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto space-y-[50px]">
          <div className="flex justify-between relative max-sm:flex-col max-sm:gap-2">
            <div className="flex flex-shrink-0 w-[652px] max-sm:w-full gap-6">
              <div className="size-[164px] max-sm:hidden flex justify-center items-center bg-black rounded-[40px]">
                <img src="/img/logo2.png" className="size-[124px]" alt="" />
              </div>
              {/* <div className="rounded-[40px] bg-[rgba(0,0,0,0.30)] max-sm:bg-[rgba(255,255,255,8%)] backdrop-blur-[4px] flex gap-6 p-[20px] max-sm:w-full">
                <img src="/img/letter.png" className="max-h-[124px] max-sm:hidden" alt="" />
                <div className="flex flex-col gap-4 w-[236px] max-sm:w-full">
                  <input
                    type="text"
                    placeholder="Your email"
                    className="bg-[rgba(3,3,3,0.50)] rounded-[60px] h-[56px] text-center"
                  />
                  <input
                    type="submit"
                    value="Early Access"
                    className="bg-[#030303] shadow-[0px_34px_74px_0px_#000] rounded-[60px] h-[56px] text-center"
                  />
                </div>
              </div> */}
            </div>
            <ul className="space-y-[12px] py-[18px] max-sm:text-center flex flex-col justify-center">
              <li>
                <a
                  href="https://docs.newera.finance/legal"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  href="https://docs.newera.finance/legal/privacy-policy"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://docs.newera.finance"
                  className="text-white opacity-50 transition-all hover:opacity-100"
                >
                  Docs
                </a>
              </li>
            </ul>
          </div>
          <div className="mx-auto w-[900px] max-w-[calc(100%-20px)] p-[20px] rounded-[60px] overflow-hidden bg-[rgba(255,255,255,0.04)] backdrop-blur-[10px] px-[24px] flex justify-between items-center">
            <img src="/img/copy.svg" className="h-[28px] max-sm:block max-sm:h-auto" alt="" />
            <ul className="flex gap-6 items-center max-sm:hidden">
              <li>
                <img src="/img/twitter.svg" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  )
}
