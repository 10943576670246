import clsx from 'clsx'
import { FC } from 'react'

export interface ColumnsDataType<T> {
  name: string
  key: string
  render: (data: T) => string | JSX.Element
  comingSoon?: boolean
}

interface ExplorerTableProps {
  columnsData: ColumnsDataType<any>[]
  data: any[]
}

const ExplorerTable: FC<ExplorerTableProps> = ({ columnsData, data }) => {
  return (
    <div className="w-full overflow-x-auto overflow-y-auto py-4">
      <table className="w-full" style={{ borderSpacing: '0 4px', borderCollapse: 'separate' }}>
        <thead className="max-sm:hidden">
          <tr className="h-[54px]">
            {columnsData.map((column, index) => (
              <th
                key={column.key}
                className={clsx('relative text-left p-3 text-sm font-normal bg-[#FFFFFF05]', {
                  'rounded-l-xl': index === 0,
                  'rounded-r-xl': index === columnsData.length - 1,
                  'min-w-28': index !== 0,
                })}
              >
                <span className="opacity-50">{column.name}</span>
                {column.comingSoon && (
                  <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-2 left-1/2 -translate-x-1/2 px-2">
                    Soon
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="sm:space-x-4 max-sm:space-x-0 max-sm:space-y-2">
          {data.map((data, index) => (
            <tr key={index} className="max-sm:flex max-sm:flex-col max-sm:m-0 ">
              {columnsData.map((column, innerIndex) => (
                <td
                  key={column.key}
                  className={clsx('p-3 bg-[#FFFFFF05] max-sm:w-full', {
                    'rounded-l-xl': innerIndex === 0,
                    'rounded-r-xl': innerIndex === columnsData.length - 1,
                  })}
                >
                  <span className="hidden max-sm:block opacity-40">
                    {column.key === 'category' || column.key === 'logo' || column.key === 'operations'
                      ? ''
                      : `${column.key}:`}
                  </span>
                  {column.render(data)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ExplorerTable
