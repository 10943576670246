import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { FC, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

gsap.registerPlugin(ScrollTrigger)

const JustForYouSection: FC = () => {
  const sectionRef = useRef<HTMLElement>(null)

  const gsapAnimations = useCallback(() => {
    const mm = gsap.matchMedia()
    let tl: gsap.core.Timeline | undefined

    mm.add('(min-width: 1024px)', () => {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#just-for-you-section',
          start: '50% 50%',
          end: '+=920',
          scrub: true,
          pin: true,
          pinnedContainer: '#page-wrapper',
          pinReparent: true,
          anticipatePin: 1,
          pinSpacing: true,
        },
      })

      tl.to('#just-for-you-scroll', { top: -920, ease: 'none', duration: 1 }, 0)
      tl.to('#just-for-you-card-1', { rotate: '4deg', left: 8, ease: 'none', duration: 1 }, 0)
      tl.to('#just-for-you-card-3', { rotate: '0deg', left: 0, ease: 'none', duration: 1 }, 0)
      tl.to(
        '#just-for-you-card-2',
        {
          rotate: '0deg',
          left: 0,
          ease: 'none',
          duration: 0.5,
          filter: 'grayscale(0)',
        },
        0
      )
      tl.to(
        '#just-for-you-card-2',
        {
          rotate: '2deg',
          left: 4,
          duration: 0.5,
          filter: 'grayscale(1)',
        },
        0.5
      )
      tl.to('#just-for-you-card-1', { filter: 'grayscale(1)', duration: 0.5 }, 0)
      tl.to('#just-for-you-card-3', { filter: 'grayscale(0)', duration: 0.5 }, 0.5)
    })

    return tl
  }, [])

  const addVideoConfigurations = () => {
    if (!sectionRef.current) return
    const videos = document.querySelectorAll<HTMLVideoElement>('#just-for-you-section video')

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videos.forEach((video) => video.play())
          }
        })
      },
      { threshold: 0.5 }
    )

    observer.observe(sectionRef.current as Element)

    return () => {
      observer.disconnect()
    }
  }

  useEffect(() => {
    const tl = gsapAnimations()
    addVideoConfigurations()
    return () => {
      tl?.scrollTrigger?.kill()
      tl?.kill()
    }
  }, [gsapAnimations])

  return (
    <section ref={sectionRef} id="just-for-you-section" className="my-[200px] relative w-full">
      <img
        src="/img/sakura-1.png"
        alt=""
        className="lg:hidden absolute left-0 -top-72 max-sm:-top-80 w-[450px] h-[400px]"
      />
      <img src="/img/sakura-2.png" alt="" className="lg:hidden absolute left-0 -bottom-72 w-[450px] h-[400px]" />
      <img
        src="/img/sakura-3.png"
        alt=""
        className="lg:hidden absolute right-0 top-1/2 -translate-y-1/2 w-[400px] h-[450px]"
      />
      <video
        muted
        loop
        src="/videos/Sakura3_1.mp4"
        className="max-lg:hidden w-[900px] absolute right-0 top-1/2 -translate-y-1/2 z-0"
      />
      <div className="max-lg:hidden absolute left-0 top-1/2 -translate-y-1/2 z-0 h-screen">
        <video muted loop src="/videos/Sakura.mp4" className="size-full" />
      </div>
      <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto flex max-lg:flex-col gap-8">
        <div className="w-full relative flex justify-center items-center">
          <p className="font-semibold relative z-10 text-[38px] max-sm:text-2xl max-w-[366px] max-lg:text-center leading-10">
            And we’ve built it just for{' '}
            <span
              className="font-black italic p-1"
              style={{
                background:
                  'conic-gradient(from 128.82deg at -12.78% 40.22%, #FF1A41 -4.77deg, #6200FF 11.95deg, #FFFFFF 218.6deg, #FF2748 258.6deg, #FFECEF 299.35deg, #FFFFFF 351.38deg, #FF1A41 355.23deg, #6200FF 371.95deg)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              you
            </span>
          </p>
          <div
            className="w-[168px] h-[168px] rounded-full absolute top-1/2 -left-[168px] -translate-x-1/2 -translate-y-1/2"
            style={{
              background:
                'conic-gradient(from 92.32deg at -30.55% 36.97%, #FF1A41 -82.09deg, #FF264B 17.02deg, #FFFFFF 84.5deg, #FFFFFF 117.24deg, #FF2748 154.31deg, #6200FF 238.49deg, #FF1A41 277.91deg, #FF264B 377.02deg)',
            }}
          />
        </div>
        <div className="w-full lg:h-[360px] relative">
          <div
            id="just-for-you-scroll"
            className="lg:absolute lg:top-0 lg:left-0 lg:right-0 flex flex-col gap-10 max-lg:items-center"
          >
            <StyledCard
              id="just-for-you-card-1"
              className="sm:h-[400px] p-8 max-sm:p-4 max-lg:max-w-96 lg:origin-bottom-right"
              title="Web3 Businesses & DAO’s:"
              description="Secure your Treasury Funds in Real World Assets and earn passive, secure yield. Leverage from our Market Insights to diversify across projects enabling sophisticated investment decisions"
              secondImageSrc="/img/shield-tick.png"
            />
            <StyledCard
              id="just-for-you-card-2"
              className="sm:h-[400px] p-8 max-sm:p-4 max-lg:max-w-96 lg:-rotate-[2deg] lg:left-1 lg:grayscale lg:origin-right"
              title="For the Inflation Fighters:"
              description="Concerned about currency devaluation in your country? Easily diversify to real-world assets for a stable store of value."
              secondImageSrc="/img/globe-gradient.png"
            />
            <StyledCard
              id="just-for-you-card-3"
              className="sm:h-[400px] p-8 max-sm:p-4 max-lg:max-w-96 lg:-rotate-[4deg] lg:left-2 lg:origin-top-right lg:grayscale"
              title="Degen Users:"
              description="Want to diversify beyond Doge & Pepe? Use RWAs as your safety net – whether as a DeFi collateral or as a classic trading pair. Secure your Profits during the market cycles"
              secondImageSrc="/img/house-gradient.png"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default JustForYouSection

const StyledCardParent = styled.div`
  border-radius: 28px;
  width: 100%;
  background: #ffffff0a;
  border: 1px solid rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(60px);
  flex-shrink: 0;
  position: relative;
`

const StyledCard: FC<{
  id?: string
  className?: string
  title: string
  description: string
  secondImageSrc: string
}> = ({ id, className, title, description, secondImageSrc }) => {
  return (
    <StyledCardParent id={id} className={className}>
      <div
        className="w-full rounded-xl bg-[#FFFFFF08] p-6 flex justify-center items-center gap-[10px] relative"
        style={{
          border: '0.8px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(255, 255, 255, 0.027) 0%, rgba(255, 255, 255, 0.063) 100%)',
        }}
      >
        <div
          className="size-[74px] flex justify-center items-center relative rounded-xl"
          style={{
            backdropFilter: 'blur(10.09px)',
            background:
              'linear-gradient(5.15deg, rgba(255, 255, 255, 0.01) -10.58%, rgba(255, 255, 255, 0.04) 110.88%)',
          }}
        >
          <img src="/img/gradient-logo.png" alt="Logo" className="w-[46px] h-8" />
        </div>
        <div
          className="size-[74px] flex justify-center items-center relative rounded-xl"
          style={{
            backdropFilter: 'blur(10.09px)',
            background:
              'linear-gradient(5.15deg, rgba(255, 255, 255, 0.01) -10.58%, rgba(255, 255, 255, 0.04) 110.88%)',
          }}
        >
          <img src={secondImageSrc} alt="Logo" className="w-[34px] h-[34px]" />
        </div>
        <div className="w-[180px] h-[180px] absolute -top-20 left-1/2 -translate-x-1/2 bg-[#D9D9D9] rounded-full blur-[64px] opacity-10" />
      </div>
      <div className="space-y-2 mt-6">
        <p className="font-medium text-xl max-sm:text-base">{title}</p>
        <p className="text-lg opacity-60 max-sm:text-sm">{description}</p>
      </div>
    </StyledCardParent>
  )
}
