import { FC } from 'react'

const OnchainMarketplaceSection: FC = () => {
  return (
    <section className="my-[100px] relative w-full bg-white text-black h-screen min-h-[880px] overflow-hidden">
      <img
        src="/img/sakura-2.png"
        alt="sakura tree"
        className="absolute -left-10 max-sm:opacity-40 top-1/3 sm:top-1/2 -translate-y-1/2 w-[450px] h-[400px]"
      />
      <img
        src="/img/sakura-2.png"
        alt="sakura tree"
        className="absolute -right-10 max-sm:opacity-40 top-2/3 sm:top-1/2 -translate-y-1/2 w-[450px] h-[400px] rotate-180"
      />
      <div className="flex flex-col justify-center items-center h-full mt-10 sm:mt-20 relative z-10">
        <p className="text-xl">NewEra Finance</p>
        <p className="font-semibold text-2xl sm:text-[38px] sm:leading-[50px] max-w-96 sm:max-w-[604px] text-center mt-5">
          The Secondary Marketplace <span className="font-light">for the</span> New Era of Finance
        </p>
        <p className="text-xl max-w-[633px] text-center mt-6 max-sm:text-sm">
          Best Prices for RWA. Best User Experience. Trade Trustless, Fully Compliant. NewEra is the first decentralized
          exchange with the compliance of traditional finance.
        </p>
        <a
          href="/#/swap"
          className="py-3 w-full max-w-72 flex justify-center items-center bg-black rounded-full text-white font-bold mt-6"
        >
          Launch App
        </a>
      </div>
      <div className="absolute size-[476px] max-sm:size-72 top-10 sm:top-0 left-1/2 -translate-x-1/2 -translate-y-1/3">
        <img src="/img/onc-mar-sun.png" alt="sun" className="size-full" />
        <img
          src="/img/new-era-logo-black.png"
          alt="New Era Logo"
          className="absolute bottom-0 sm:bottom-[10%] left-1/2 -translate-x-1/2 scale-[45%] sm:scale-[66%]"
        />
      </div>
      <div className="min-w-[1024px] w-[120vw] h-60 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#030109] rounded-[50%]"></div>
      <div className="min-w-[1024px] w-[120vw] h-60 absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 bg-[#030109] rounded-[50%]"></div>
    </section>
  )
}

export default OnchainMarketplaceSection
