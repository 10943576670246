import clsx from 'clsx'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { FC, useEffect, useRef } from 'react'

gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.normalizeScroll(false)

const paragraphs = [
  'Overwhelmed by RWA offers?',
  'Tired of having thousand tabs open for research?',
  'Trading in & out of RWA’s is a hassle?',
  'NewEra Finance makes RWAs accessible for you.',
  'The easiest way to explore & trade your RWA’s.',
]

const IntroductionSection: FC = () => {
  const paragraphRefs = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '#introduction-section',
        start: '10% 10%',
        end: '+=1500',
        scrub: true,
        pin: true,
        pinnedContainer: '#page-wrapper',
        pinReparent: true,
        anticipatePin: 1,
        pinSpacing: true,
      },
    })

    paragraphRefs.current.forEach((el, index) => {
      timeline.to(
        el,
        {
          opacity: 1,
          fontWeight: 600,
          fontStyle: 'normal',
          duration: 0.5,
        },
        index * 0.5
      )
    })

    return () => {
      timeline.scrollTrigger?.kill()
      timeline.kill()
    }
  }, [])

  return (
    <section
      id="introduction-section"
      className="max-sm:px-2 h-lvh py-[100px] mt-[calc(100lvh-100svh)] max-sm:py-[50px] w-full relative flex justify-center items-center"
    >
      <div className="w-[963px] max-w-[calc(100%-20px)] mx-auto">
        <div
          id="scroll-container-intro"
          className="relative font-mono flex justify-center flex-col text-4xl max-sm:text-2xl"
        >
          {paragraphs.map((text, index) => (
            <div
              key={index}
              id={`paragraph-${index}`}
              ref={(el) => el && (paragraphRefs.current[index] = el)}
              className={clsx('max-sm:text-center relative italic opacity-25', {
                'mt-20': index === 3,
                'mt-3': index !== 3,
              })}
            >
              {text}
            </div>
          ))}

          {/* <img src="/img/arrow-moving.svg" width={196} height={54} className="absolute -top-24 left-6" />
          <img
            src="/img/arrow-moving.svg"
            width={196}
            height={54}
            className="absolute -bottom-24 right-6 -scale-y-100"
          /> */}
        </div>
      </div>
      <img
        src="/img/landing-introduction-gl.png"
        alt="introduction"
        className="max-xl:hidden absolute top-1/2 left-0 -translate-x-2/3 -translate-y-1/2 size-[476px] opacity-70 blur-[344px]"
      />
      <img
        src="/img/landing-introduction-gl.png"
        alt="introduction"
        className="max-xl:hidden absolute top-1/2 left-0 -translate-x-2/3 -translate-y-1/2 size-[476px]"
      />
    </section>
  )
}

export default IntroductionSection
