import clsx from 'clsx'
import { FC } from 'react'
import styled from 'styled-components/macro'

// interface ExplorerSoonProps {}

const MainEl = styled.div`
  background-color: #ffffff05;
  backdrop-filter: blur(132px);
  border-radius: 12px;
  border: 1px solid #ffffff3d;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1210px;
  position: relative;
  max-height: 90vw;
  height: 66vh;
  overflow: hidden;
`

const ExplorerSoon: FC = () => {
  return (
    <section className="min-h-screen h-full relative w-full flex flex-col justify-center items-center">
      <img src="/img/border.png" className="w-[1121px] left-0 right-0 mx-auto bottom-[100px] absolute max-sm:hidden" />
      <img
        src="/img/explorer-soon-bg.png"
        alt="Background Image"
        className="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 absolute max-w-max grayscale max-sm:hidden"
      />
      <div className="size-72 rounded-full bg-white blur-[400px] opacity-75 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
      <div className="w-full h-1/2 absolute left-0 top-0 bg-[url(./img/pattern.png)] mix-blend-color-burn opacity-50 max-sm:hidden"></div>
      <div className="w-full h-full flex justify-center items-center">
        <MainEl className="max-sm:mt-36">
          {[0, 1, 2, 3].map((i) => (
            <img
              key={i}
              src="/img/explorer-union.svg"
              alt=""
              className="w-max"
              style={{
                position: 'absolute',
                right: i < 2 ? -120 : undefined,
                left: i > 1 ? -120 : undefined,
                bottom: i === 0 || i === 3 ? -120 : undefined,
                top: i > 0 && i < 3 ? -120 : undefined,
                rotate: i * -90 + 'deg',
              }}
            />
          ))}
          <div className="text-[38px] max-sm:text-2xl">Explorer</div>
          <div className="text-[56px] max-sm:text-4xl font-semibold max-sm:max-w-40 text-center">Coming Soon</div>
          <SmallSquare name="OUSG" imgSrc="/img/explorer-logos/ousg.png" top={15} left={50} />
          <SmallSquare name="Templeton" imgSrc="/img/explorer-logos/templeton.png" top={20} left={32} rotate={-7} />
          <SmallSquare name="BlackRock" imgSrc="/img/explorer-logos/blackrock.png" top={20} left={68} rotate={7} />
          <SmallSquare name="USDY" imgSrc="/img/explorer-logos/usdy.png" top={37} left={17} rotate={-7} />
          <SmallSquare name="BackedFi" imgSrc="/img/explorer-logos/backedFi.png" top={37} left={83} rotate={7} />
          <SmallSquare name="USYC" imgSrc="/img/explorer-logos/usyc.png" top={60} left={12} rotate={-7} />
          <SmallSquare name="Spiko" imgSrc="/img/explorer-logos/spiko.png" top={60} left={88} rotate={7} />
          <SmallSquare name="Open Eden" imgSrc="/img/explorer-logos/open-eden.png" top={82} left={25} rotate={-7} />
          <SmallSquare name="HashNote" imgSrc="/img/explorer-logos/hashnote.png" top={82} left={75} rotate={7} />
          <SmallSquare name="SuperState" imgSrc="/img/explorer-logos/superstate.png" top={89} left={50} />
        </MainEl>
      </div>
      <footer className="absolute left-0 w-full bottom-0 flex justify-between p-[48px] max-sm:static max-sm:p-[20px] max-sm:mt-[50px] max-sm:flex-col max-sm:gap-2 max-sm:text-center">
        <p className="text-[18px] text-white opacity-30 max-sm:text-[14px]">NewEra Finance All Rights Reserved</p>
        <a
          href="https://docs.newera.finance/legal/privacy-policy"
          className="text-[18px] text-white opacity-60 max-sm:text-[14px] transition-all hover:opacity-100"
        >
          Privacy Policy
        </a>
      </footer>
    </section>
  )
}

export default ExplorerSoon

const SmallSquare = ({
  name,
  imgSrc,
  top,
  left,
  rotate,
}: {
  name: string
  imgSrc: string
  top: number
  left: number
  rotate?: number
}) => {
  return (
    <div
      className="max-sm:size-11 size-[68px] flex justify-center items-center absolute group"
      style={{
        top: `calc(${top}% - ${window.innerWidth < 640 ? 22 : 34}px)`,
        left: `calc(${left}% - ${window.innerWidth < 640 ? 22 : 34}px)`,
      }}
    >
      <div
        className={clsx('size-[68px] max-sm:size-11 flex justify-center items-center bg-white/10 rounded-xl', {
          'hover:animate-wiggle': !rotate,
          'hover:animate-wiggle-pos': rotate && rotate > 0,
          'hover:animate-wiggle-neg': rotate && rotate < 0,
        })}
        style={{
          transform: `rotate(${rotate}deg)`,
        }}
      >
        <img
          src={imgSrc}
          alt={name}
          className="size-[46px] max-sm:size-8"
          style={{ rotate: rotate ? `${rotate * -1}deg` : '0' }}
        />
      </div>

      {/* <div className="-z-10 opacity-0 group-hover:opacity-100 absolute top-0 left-0 group-hover:-top-12 duration-200 bg-black rounded-md min-w-[68px] w-min text-nowrap text-center p-2">
        {name}
      </div> */}
    </div>
  )
}
