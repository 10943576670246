import { FC } from 'react'

const JoinUsSection: FC = () => {
  return (
    <section id="join-us-section" className="mt-96 mb-[100px] relative w-full">
      <div className="size-44 rounded-full absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/3 bg-[#FF264B] blur-[94px]"></div>
      <div className="size-44 rounded-full absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/3 bg-[#FF264B]"></div>
      <div className="w-[900px] max-w-[calc(100%-20px)] bg-gradient-to-b from-[#FFFFFF0A] to-transparent mx-auto flex flex-col items-center relative max-sm:min-h-[640px] h-[80dvh] rounded-[28px] overflow-hidden backdrop-blur-[132px]">
        {/* <img src="/img/join-us-bg.png" alt="" className="absolute bottom-0" /> */}
        <div className="size-[476px] rounded-full absolute z-10 top-0 left-1/2 -translate-x-1/2 -translate-y-3/4 blur-[100px] bg-[#FF264B]"></div>
        <div className="max-sm:hidden w-[154px] h-[154px] rounded-full bg-[#D9D9D9] blur-[230px] absolute left-1/2 -translate-x-1/2 -top-28 z-10"></div>
        <div className="flex flex-col justify-center items-center mt-24 relative z-10">
          <p className="font-light text-[32px] italic">Join our growing</p>
          <p className="font-extrabold text-[86px]">280k+</p>
          <p className="font-light text-[32px] italic">Community</p>
        </div>
        <a
          href="https://x.com/newera_finance"
          target="_blank"
          className="flex justify-center items-center mt-8 text-white bg-[#29242B] rounded-full font-semibold max-sm:text-[14px] max-sm:justify-center px-6 py-4 w-72 relative z-20"
          rel="noreferrer"
        >
          Join us on <img src="/img/twitter.svg" className="ml-4" alt="" />
        </a>
        <video src="/videos/Waves.mp4" autoPlay loop muted className="absolute bottom-0 z-0" />
      </div>
    </section>
  )
}

export default JoinUsSection
