import clsx from 'clsx'
import { FC } from 'react'
import { getNetworkArray } from 'utils/networks'

import ExplorerTable, { ColumnsDataType } from './ExplorerTable/ExplorerTable'

const Explorer: FC = () => {
  return (
    <section className="min-h-screen h-full relative w-full flex flex-col justify-center items-center">
      <div className="size-72 rounded-full bg-white blur-[400px] opacity-75 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
      <div
        className="w-11/12 max-w-[1560px] bg-[#FFFFFF10] p-8 rounded-[28px] flex flex-col mt-36"
        style={{
          border: '1px solid',
          borderImageSource: 'linear-gradient(360deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.12) 100%)',
        }}
      >
        <div className="h-12 flex space-x-2 max-sm:flex-col max-sm:h-auto max-sm:space-y-2 max-sm:space-x-0">
          <div className="h-full rounded-full bg-[#FFFFFF24] p-6 max-sm:p-3 flex justify-center items-center cursor-pointer font-medium">
            Top RWA Tokens
          </div>
          <div className="relative h-full rounded-full bg-[#FFFFFF10] text-white/50 p-6 max-sm:p-3 flex justify-center items-center font-medium">
            Top Pairs
            <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-2 -right-4 -translate-x-1/2 px-2">
              soon
            </span>
          </div>
          <div className="relative h-full rounded-full bg-[#FFFFFF10] text-white/50 p-6 max-sm:p-3 flex justify-center items-center font-medium">
            Transactions
            <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-2 -right-4 -translate-x-1/2 px-2">
              soon
            </span>
          </div>
          <div className="flex flex-grow justify-center items-center">
            <div className="relative w-full h-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full h-12 pl-10 rounded-full bg-[#FFFFFF10] p-2 text-sm font-medium text-[#FFFFFF] focus:outline-none"
              />
              <svg
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#FFFFFF]"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.58366 17.4998C13.9559 17.4998 17.5003 13.9554 17.5003 9.58317C17.5003 5.21092 13.9559 1.6665 9.58366 1.6665C5.2114 1.6665 1.66699 5.21092 1.66699 9.58317C1.66699 13.9554 5.2114 17.4998 9.58366 17.4998Z"
                  stroke="white"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.3337 18.3332L16.667 16.6665"
                  stroke="white"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <ExplorerTable columnsData={columnsData} data={dataArray} />
      </div>
    </section>
  )
}

export default Explorer

const swap = (symbol) => {
  window.location.href = `/#/swap?s=${symbol}`
}

const columnsData: ColumnsDataType<{
  logo: string
  name: string
  ticker: string
  networks: string[]
  category: string
  swapStatus?: string
}>[] = [
  {
    name: '#',
    key: 'logo',
    render: (data) => (
      <div className="w-7 h-7">
        <img src={data.logo} alt={data.name} className="w-7 h-7 rounded-full bg-white" width={28} height={28} />
      </div>
    ),
  },
  {
    name: 'Name',
    key: 'name',
    render(data) {
      return (
        <div className="flex flex-col space-y-1">
          <p className="font-medium text-sm">{data.ticker}</p>
          <p className="font-medium text-sm opacity-50">{data.name}</p>
        </div>
      )
    },
  },
  {
    name: 'Networks',
    key: 'networks',
    render(data) {
      const networks = getNetworkArray(data.networks)

      return (
        <div className="flex relative">
          {networks.map((network, index) => (
            <span
              key={network.id}
              className={clsx('relative w-8 h-8 group', {
                '-ml-3': index !== 0,
              })}
            >
              <img
                src={network.logo}
                alt={network.name}
                width={32}
                height={32}
                className="absolute top-0 left-0 rounded-full border-2 border-[#121212] w-8 h-8 bg-[#414141] group-hover:z-[100]"
              />
              <span className="absolute opacity-0 z-0 top-0 left-1/2 -translate-x-1/2 group-hover:opacity-100 group-hover:-top-5 duration-200 text-xs">
                {network.name}
              </span>
            </span>
          ))}
        </div>
      )
    },
  },
  {
    name: 'Marketcap',
    key: 'marketcap',
    comingSoon: true,
    // render: (data) => <p className="text-sm font-medium">{'$' + normalizeNumber(data.marketCap)}</p>,
    render: () => <span className="opacity-50">--</span>,
  },
  {
    name: 'Category',
    key: 'category',
    render: (data) => (
      <div className="w-full bg-[#4C2CFF33] text-[#C397FF] text-sm font-medium text-center rounded-full py-2">
        {data.category}
      </div>
    ),
  },
  {
    name: 'APR',
    key: 'apr',
    comingSoon: true,
    render: () => (
      <span className="opacity-50">--</span>
      // <p
      //   className={clsx('text-sm font-medium', {
      //     'text-green-400': data.APR > 0,
      //     'text-red-400': data.APR < 0,
      //   })}
      // >
      //   {(data.APR > 0 ? '+' : data.APR < 0 ? '-' : '') + data.APR.toLocaleString() + '%'}
      // </p>
    ),
  },
  {
    name: 'Vol. 24h',
    key: 'volume',
    comingSoon: true,
    render: () => <span className="opacity-50">--</span>,
    // render: (data) => <p className="text-sm font-medium">{'$' + normalizeNumber(data.volume.last24)}</p>,
  },
  {
    name: 'Price',
    key: 'price',
    comingSoon: true,
    render: () => <span className="opacity-50">--</span>,
    // render: (data) => <p className="text-sm font-medium">{'$' + normalizeNumber(data.price)}</p>,
  },
  {
    name: 'Rating',
    key: 'rating',
    render: () => (
      <span className="opacity-50">--</span>
      // <div className="flex space-x-4 w-max">
      //   <p className="text-sm font-medium p-2 px-3 rounded-full bg-[#FFFFFF14] flex items-center space-x-2">
      //     <div className="w-4 h-4 rounded-full bg-white"></div>
      //     <span>{data.rating.first}</span>
      //   </p>
      //   <p className="text-sm font-medium p-2 px-3 rounded-full bg-[#FFFFFF14] flex items-center space-x-2">
      //     <div className="w-4 h-4 rounded-full bg-white"></div>
      //     <span>{data.rating.second}</span>
      //   </p>
      // </div>
    ),
  },
  {
    name: '',
    key: 'operations',
    render: (data) => (
      <div className="flex space-x-2 justify-end">
        {data?.swapStatus ? (
          <button
            className={clsx('text-sm font-medium p-2 px-3 rounded-full flex items-center space-x-2', {
              'bg-[#FFFFFF] text-black': data.swapStatus.toLocaleLowerCase() === 'swap',
              'bg-[#FFFFFF15] text-white': data.swapStatus.toLocaleLowerCase() === 'soon',
            })}
            disabled={data.swapStatus.toLocaleLowerCase() !== 'swap'}
            onClick={() => swap(data.ticker)}
          >
            <span>{data.swapStatus}</span>
          </button>
        ) : (
          <span className="opacity-50">--</span>
        )}
      </div>
    ),
  },
]

const normalizeNumber = (number: number) => {
  if (number >= 1_000_000_000) {
    return (number / 1_000_000_000).toFixed(2) + ' B'
  } else if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(2) + ' M'
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(2) + ' K'
  }
  return number.toLocaleString()
}

const dataArray = [
  {
    logo: '/img/explorer-logos/usdm.jpeg',
    name: 'Mountain Procotol',
    ticker: 'USDM',
    networks: ['arbitrum', 'ethereum', 'base', 'injective', 'optimism', 'polygon'],
    category: 'U.S. TBill',
    swapStatus: 'Swap',
  },
  {
    logo: '/img/explorer-logos/usdl.jpg',
    name: 'Lift Dollar',
    ticker: 'USDL',
    networks: ['arbitrum', 'ethereum'],
    category: 'U.S. TBill',
    swapStatus: 'Swap',
  },
  {
    logo: '/img/explorer-logos/usdy.png',
    name: 'Ondo U.S. Dollar Yield',
    ticker: 'USDY',
    networks: ['arbitrum', 'ethereum', 'solana', 'aptos', 'mantle', 'sui', 'noble'],
    category: 'U.S. TBill',
    swapStatus: 'Swap',
  },
  {
    logo: '/img/explorer-logos/usyc.png',
    name: 'Hashnote',
    ticker: 'USYC',
    networks: ['ethereum', 'near', 'canto'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/blackrock.png',
    name: 'BlackRock',
    ticker: 'BUIDL',
    networks: ['arbitrum', 'ethereum', 'avalanche', 'optimism', 'polygon', 'aptos'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/templeton.png',
    name: 'Franklin Templeton Bejin Inv.',
    ticker: 'FOBXX',
    networks: ['arbitrum', 'ethereum', 'avalanche', 'base', 'polygon', 'aptos', 'stellar'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/superstate.png',
    name: 'Superstate',
    ticker: 'USTB',
    networks: ['ethereum'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/ousg.png',
    name: 'Ondo Short-Term US Gov.',
    ticker: 'OUSG',
    networks: ['ethereum', 'polygon', 'stellar'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/open-eden.png',
    name: 'Open Eden',
    ticker: 'TBILL',
    networks: ['arbitrum', 'ethereum'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/spiko.png',
    name: 'Spiko',
    ticker: 'USTBL',
    networks: ['ethereum', 'polygon'],
    category: 'U.S. TBill',
    swapStatus: 'Soon',
  },
  {
    logo: '/img/explorer-logos/anemoy.png',
    name: 'Centrifuge Anemoy',
    ticker: 'LTF',
    networks: ['ethereum', 'blast', 'base'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/backedFi.png',
    name: 'Backed Finance',
    ticker: 'bIB01',
    networks: ['ethereum', 'blast', 'base'],
    category: 'U.S. TBill',
    swapStatus: 'Soon',
  },
  {
    logo: '/img/explorer-logos/dinari.jpg',
    name: 'Dinari WisdomTree',
    ticker: 'USFR.d',
    networks: ['arbitrum', 'ethereum', 'blast', 'base'],
    category: 'U.S. TBill',
    swapStatus: 'Soon',
  },
  {
    logo: '/img/explorer-logos/TrueFi.png',
    name: 'TrueFi',
    ticker: 'tfBILL',
    networks: ['ethereum'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/STBT.png',
    name: 'Matrixdock',
    ticker: 'STBT',
    networks: ['ethereum'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/opentrade.jpg',
    name: 'OpenTrade Flex U.S. T-Bill',
    ticker: 'XTBT',
    networks: ['ethereum', 'avalanche'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/maple.jpg',
    name: 'Maple',
    ticker: 'MPLcashUSDC-SPL',
    networks: ['solana'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/midas.png',
    name: 'Midas',
    ticker: 'mTBILL',
    networks: ['ethereum'],
    category: 'U.S. TBill',
  },
  {
    logo: '/img/explorer-logos/opentrade.jpg',
    name: 'OpenTrade Fix U.S. T-Bill',
    ticker: 'TBV',
    networks: ['ethereum'],
    category: 'U.S. TBill',
  },
]
