import { FC, useEffect, useRef } from 'react'

const OptimizedTradesSection: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) {
      return
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoEl.play()
        } else {
          videoEl.currentTime = 0
          videoEl.pause()
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
      root: null,
      rootMargin: '0px',
    })
    observer.observe(videoEl)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <section className="py-[100px] w-full">
      <div className="w-[900px] max-w-[calc(100%-20px)] mx-auto flex flex-col max-sm:items-center">
        <p className="opacity-50 text-xl max-sm:text-sm max-sm:text-center">NewEra Swap</p>
        <p className="text-[38px] font-medium max-sm:text-[18px] mt-5 max-sm:text-center">
          Optimized <span className="font-black">RWA</span> Trades
        </p>
        <p className="opacity-50 text-xl max-sm:text-sm mt-5 max-sm:text-center">
          From U.S. Treasury Bonds to Gold. Swap from our advanced liquidity aggregation across NewEra Pools & 130+
          exchange sources.
        </p>
        <div className="mt-16 max-sm:text-center">
          <a
            href="https://docs.newera.finance/"
            target="_blank"
            className="flex justify-center items-center mt-8 text-white bg-white/10 rounded-full font-semibold max-sm:text-[14px] max-sm:justify-center px-6 py-4 w-72"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
        <div className="mt-14 flex max-sm:flex-col justify-center gap-5 max-sm:gap-2 max-sm:max-w-96 items-stretch">
          <div className="relative">
            <img
              src="/img/landing-swap.png"
              className="bg-white/[4%] backdrop-blur-[100px] rounded-[28px] max-sm:rounded-xl relative z-10"
              alt=""
            />
            <p className="absolute top-0 left-0 font-medium text-xl max-sm:text-base p-8 max-sm:p-5 max-w-[340px] z-10">
              A <span className="font-black">Single Interface</span> for All Your Trades
            </p>
            <img src="/img/landing-swap-union.png" className="absolute top-0 -left-[72px] grayscale z-0" alt="" />
          </div>
          <div className="relative h-full flex-shrink-0">
            <video ref={videoRef} src="/videos/Fuji.mp4" autoPlay playsInline muted className="size-full"></video>
            {/* <img
              src="/img/landing-swap-fuji.png"
              className="bg-white/[4%] backdrop-blur-[100px] rounded-[28px] max-sm:rounded-xl"
              alt=""
            /> */}
            <p className="absolute top-0 left-0 font-medium text-xl max-sm:text-base p-8 max-sm:p-5">
              Select between stablecoins, U.S. TBill’s, Commodities, Stocks and <span className="font-black">more</span>{' '}
              <span className="font-bold">in only a few clicks</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OptimizedTradesSection
