// src/store/useSwapStore.js
import { formatUnits, parseUnits } from '@ethersproject/units'
import { create } from 'zustand'

const useSwapStore = create((set, get) => ({
  // Variables
  src: {
    chainId: 42161,
    decimals: 18,
    symbol: 'ETH',
    name: 'Ether',
    isNative: true,
    isToken: false,
  },
  dst: '',
  amount: 0,
  price: '',
  slippage: 0.5, // default slippage
  quote: null,
  impact: 0,

  // Actions
  defaultSrcDst: () => {
    set({
      src: {
        chainId: 42161,
        decimals: 18,
        symbol: 'ETH',
        name: 'Ether',
        isNative: true,
        isToken: false,
      },
      dst: '',
      amount: 0,
      price: '',
      slippage: 0.5, // default slippage
      quote: null,
      impact: 0,
    })
  },

  fetchQuote: async () => {
    const { src, dst, amount } = get()
    // console.log('Fetching quote:', src, dst, amount)
    if (!src || !dst || !amount) return
    if (!src.address) src.address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    if (!dst.address) dst.address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    try {
      const response = await fetch(
        `https://proxy.newera.finance/?url=${encodeURIComponent(
          `https://api.1inch.dev/swap/v6.0/${dst.chainId}/quote?src=${src.address}&dst=${
            dst.address
          }&amount=${parseUnits(amount, src.decimals)}`
        )}`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data = await response.json()
      if (data.error) return console.error('Error fetching quote:', data.error)
      const output = formatUnits(data.dstAmount, dst.decimals)

      const response2 = await fetch(
        `https://proxy.newera.finance/?url=${encodeURIComponent(
          `https://api.1inch.dev/swap/v6.0/${dst.chainId}/quote?src=${dst.address}&dst=${src.address}&amount=${data.dstAmount}`
        )}`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data2 = await response2.json()
      if (data2.error) return console.error('Error fetching quote:', data2.error)
      return {
        dstAmount: output,
        price: `1 ${src.symbol} = ${output / amount} ${dst.symbol}`,
        impact: 100 - (data2.dstAmount / parseUnits(amount, src.decimals)) * 100,
      }
    } catch (error) {
      console.error('Error fetching quote:', error)
    }
  },

  allowance: async (address: string) => {
    const { src } = get()
    if (!src.address) return 1
    if (!src) return
    try {
      const response = await fetch(
        `https://proxy.newera.finance/?url=${encodeURIComponent(
          `https://api.1inch.dev/swap/v6.0/${src.chainId}/approve/allowance?tokenAddress=${src.address}&walletAddress=${address}`
        )}`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data = await response.json()
      return data.allowance
    } catch (error) {
      console.error('Error checking allowance:', error)
      return 0
    }
  },

  approve: async () => {
    const { src, amount } = get()
    if (!src.address) src.address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    if (!src) return
    try {
      const response = await fetch(
        `https://proxy.newera.finance/?url=${encodeURIComponent(
          `https://api.1inch.dev/swap/v6.0/${src.chainId}/approve/transaction?tokenAddress=${
            src.address
          }&amount=${parseUnits(amount, src.decimals)}`
        )}`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error approving transaction:', error)
      return null
    }
  },

  swap: async (address: string, disableEstimate = false) => {
    const { src, dst, amount, slippage } = get()
    if (!src || !dst || !amount) return
    if (!src.address) src.address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    if (!dst.address) dst.address = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    try {
      const response = await fetch(
        `https://proxy.newera.finance/?url=${encodeURIComponent(
          `https://api.1inch.dev/swap/v6.0/${dst.chainId}/swap?src=${src.address}&dst=${
            dst.address
          }&amount=${parseUnits(
            amount,
            src.decimals
          )}&from=${address}&slippage=${slippage}&disableEstimate=${disableEstimate}`
        )}`,
        {
          headers: { Authorization: 'Bearer MZ2eNFE1AC2uqnjTiaTvJ7H1mOsTTVah' },
        }
      )
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error executing swap:', error)
      return null
    }
  },
}))

export default useSwapStore
